<template>
  <div>
    <div class="card-toolbar mb-5">
        <router-link v-if="$can('users.create')"
            to="/users/users/create"
            class="btn btn-primary font-weight-bolder"
            ><v-icon>mdi-plus</v-icon>{{ $t('users.add_user')}}</router-link
          >
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
            <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
        </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
        <div class="card-body">
            <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="name">{{$t('name')}}</label>
                        <input v-model="filters.name" type="text" id="name" class="form-control">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="email">{{$t('email')}}</label>
                        <input v-model="filters.email" type="text" id="email" class="form-control">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="phone">{{$t('phone')}}</label>
                        <input v-model="filters.phone" type="text" id="phone" class="form-control">
                    </div>
                    
                    <div class="form-group d-inline-flex col-md-6">
                        <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                        </button>
                        <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <div class="row justify-content-end p-4">
            <export-data :data-list="dataList" :fields="json_fields" :file-name="$t('users.users_management')"></export-data>
        </div>
          
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

            <template slot="actions" slot-scope="props">
              <v-btn v-b-tooltip.hover :title="$t('edit')" v-if="$can('users.update')" icon color="pink" :to="`/users/users/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
                </v-btn>
                <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('users.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
            </template>
        </v-server-table>

      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService  from "@/core/services/api.service";

    export default {
        name: "index-users",
        components: {},
        data() {
            return {
                showAdvancedSearch: false,
                dataList: [],

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    name: '',
                    status: '',
                    email: '',
                    phone: '',
                },
                columns: ['id','name', 'phone', 'email', 'created_at', 'actions'],
                data: [],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('users.name')] = 'name';
                fields[this.$t('users.phone')] = 'phone';
                fields[this.$t('users.email')] = 'email';
                fields[this.$t('users.created_at')] = 'created_at';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter')+' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        id: that.$t('#'),
                        name: that.$t('users.name'),
                        phone: that.$t('users.phone'),
                        email: that.$t('users.email'),
                        created_at: that.$t('users.created_at'),
                        actions: that.$t('global.actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query("users", {..._params}).catch(function (error) {
                            console.error(error)
                        })

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        // created() {
        //     ApiService.get('users').then(response => {
        //         this.data = response.data.data.data;
        //     }).catch(e => {
        //         console.log(e);
        //     });
        // },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.user_Management")}]);
        },
        methods: {
          openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.email = '';
                this.filters.phone = '';

                this.$refs.table.refresh();
            },

            getFetch() {
                this.$refs.table.refresh();
            },
            actionDelete(item){
                ApiService.delete(`users/${item.id}`).then(response => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch(({errors}) => {
                    console.error(errors);
                })
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

        },
    };
</script>
